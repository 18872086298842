
import { App as CapacitorApp } from '@capacitor/app';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import CategoryForm from './components/CategoryForm';
import ContentForm from './components/ContentForm';
import SubCategoryForm from './components/SubCategoryForm';
import GetAllContent from './services/ContentService';
import GetAllSubCategoryComponent from './services/SubCategoryService';

import HomePageService from './visible/HomePageService';
import { ShowOneContentService } from './visible/ShowOneContentService';
import ShowAllContentBySubCategory from './visible/ShowSubCategoryService';

import SearchPage from './visible/ShowSearchService';

import NavBarComponent from './visible/NavBarComponent';
import ShowStaticPage from './visible/ShowStaticPage';

import OneSignal from 'onesignal-cordova-plugin';
import { isMobileApp } from './Constants';



function App() {
  CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (!canGoBack) {
      CapacitorApp.exitApp();
    } else {
      window.history.back();
    }
  });

  CapacitorApp.addListener('appUrlOpen', (event) => {
    console.log("appUrlOpen event.url", event.url);
    const slug = event.url.split('.in').pop();
    console.log("appUrlOpen slug ", slug);
    if (slug) {
      window.location.href = slug;
    }
  });



  if (isMobileApp) {
    // Remove this method to stop OneSignal Debugging
    OneSignal.Debug.setLogLevel(6)

    // Replace YOUR_ONESIGNAL_APP_ID with your OneSignal App ID
    OneSignal.initialize("801df9f7-cb59-471e-b869-479476c09e8f");

    OneSignal.Notifications.addEventListener('click', async (e) => {
      let clickData = await e.notification;
      console.log("Notification Clicked : " + clickData);
    })

    OneSignal.Notifications.requestPermission(true).then((success) => {
      console.log("Notification permission granted " + success);
    })
  }

  return (
    <>
      <BrowserRouter>

        <NavBarComponent />
        <Routes>
          <Route path="/admin" element={<GetAllSubCategoryComponent />} />
          <Route path="/admin/content/list/:scid" element={<GetAllContent />} />
          <Route path="/admin/insert/category" element={<CategoryForm />} />
          <Route path="/admin/insert/subcategory" element={<SubCategoryForm />} />
          <Route path="/admin/content" element={<ContentForm />} />
          <Route path="/admin/content/:id" element={<ContentForm />} />
          <Route path="/" exact element={<HomePageService />} />
          <Route path="/subcategory/:scid" element={<ShowAllContentBySubCategory />} />
          <Route path="/content/:id" element={<ShowOneContentService />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/page/:pagename" element={<ShowStaticPage />} />
          <Route
            path="*"
            element={
              <div>
                <h2>404 Page not found</h2>
              </div>
            }
          />
        </Routes>

      </BrowserRouter>
    </>
  );
}

export default App;
